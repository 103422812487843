<template>
  <div id="download" style="padding-top: 75px">
    <div class="download">
      <div class="download-header">下载</div>
      <div class="download-main">
        <div
          class="download-item"
          v-for="(item, index) in downloadList"
          :key="index"
        >
          <div class="download-top">
            <img
              class="download-icon"
              :src="require(`../../assets/images/download-${item.value}.png`)"
              alt=""
            />
            <div class="download-platform">{{ item.platform }}</div>
          </div>
          <div
            v-if="item.platform === '网页版' || item.platform === 'Windows'"
            class="hover-main"
          >
            <img
              @click="toOrDownload(item.platform)"
              class="hover-img"
              :src="
                require(`../../assets/images/${
                  item.platform === 'Windows' ? 'window-download' : 'web-go'
                }.png`)
              "
              alt=""
            />
            <div
              style="z-index: 1"
              @click="toOrDownload(item.platform)"
              class="download-btn d-flex-center"
            >
              {{ item.platform === "网页版" ? "前往" : "立即下载" }}
            </div>
          </div>
          <div
            @click="toOrDownload(item.platform)"
            class="download-btn d-flex-center"
          >
            {{ item.platform === "网页版" ? "前往" : "立即下载" }}
          </div>
          <div
            v-if="item.platform !== '网页版' && item.platform !== 'Windows'"
            class="download-hover"
          >
            <!-- <img class="code-img" src="../../assets/images/code.png" alt="" /> -->

            <div class="code-img" ref="code-img"></div>
            <div class="code-sao">扫码下载</div>
            <div class="code-name">和慕PMS</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import downloadFile from "@/utils/downloadFile.js";
export default {
  data() {
    return {
      downloadList: [
        {
          platform: "Windows",
          value: 1
        },
        {
          platform: "Android Pad",
          value: 2
        },
        {
          platform: "iPad",
          value: 3
        },
        { platform: "网页版", value: 4 },
        {
          platform: "Android Phone",
          value: 5
        },
        {
          platform: "iPhone",
          value: 6
        },
      ],
      downloadObj: Object.freeze({
        1: "https://appdlapi.withlight.cloud/obkfxf/release",
        2: "https://appdlapi.withlight.cloud/gmsxii/release",
        3: "https://apps.apple.com/app/apple-store",
        5: "https://appdlapi.withlight.cloud/xkjazo/release",
        6: "https://apps.apple.com/app/apple-store",
      }),
    };
  },
  created() {
    this.windowResize();
    window.addEventListener("resize", this.windowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResize);
  },
  methods: {
    // 当前版本下载
    downloadFile() {
      downloadFile(this.downloadObj[1]);
    },
    // 生成二维码
    createQrCode(codeNode, qrcodeUrl) {
      let text = qrcodeUrl || "暂无内容";
      if (!codeNode) return;
      codeNode.innerHTML = '';
      this.qrcode = new QRCode(codeNode, {
        text, // 需要转换为二维码的内容
        width: 130,
        height: 130,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    windowResize() {
      if (this.windowWidth === window.innerWidth) return;
      this.windowWidth = window.innerWidth;
      if (window.innerWidth < 1001) {
        this.downloadList = [
          { platform: "Windows", value: 1 },
          { platform: "网页版", value: 4 },
          { platform: "iPad", value: 3 },
          { platform: "Android Pad", value: 2 },
          { platform: "Android Phone", value: 5 },
          { platform: "iPhone", value: 6 },
        ];
      } else {
        this.downloadList = [
          { platform: "Windows", value: 1 },
          { platform: "Android Pad", value: 2 },
          { platform: "iPad", value: 3 },
          { platform: "网页版", value: 4 },
          { platform: "Android Phone", value: 5 },
          { platform: "iPhone", value: 6 },
        ];
      }
      this.$nextTick(() => {
        this.$refs["code-img"].forEach((item, index) => {
          this.createQrCode(
            this.$refs["code-img"][index],
            this.downloadObj[
              this.downloadList.filter(
                (item1) =>
                  item1.platform !== "网页版" && item1.platform !== "Windows"
              )[index].value
            ]
          );
        });
      });
    },
    toOrDownload(platform) {
      if (platform === "网页版") {
        window.open("https://web2.homepms.com", "_blank");
      } else if (platform === "Windows") {
        this.downloadFile();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.download {
  width: 100%;
  height: 804px;
  padding: 80px 0 100px;
  box-sizing: border-box;
  border-radius: 40px 40px 0px 0px;
  background: linear-gradient(
    29deg,
    rgba(78, 161, 255, 0.8) 0%,
    rgba(13, 62, 224, 0.8) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1299px) {
    padding: 50px 0 60px;
    height: 700px;
  }
  @media screen and (max-width: 1000px) {
    padding: 53px 0 60px;
    height: 949px;
  }
  @media screen and (max-width: 749px) {
    padding: 40px 0 60px;
    height: 672px;
  }
  .download-header {
    font-size: 44px;
    font-weight: 500;
    color: #fff;
    @media screen and (max-width: 1299px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 36px;
    }
    @media screen and (max-width: 749px) {
      font-size: 32px;
    }
  }
  .download-main {
    width: 750px;
    height: 500px;
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    @media screen and (max-width: 1299px) {
      margin-top: 50px;
    }
    @media screen and (max-width: 1000px) {
      width: 500px;
      height: 750px;
    }
    @media screen and (max-width: 749px) {
      width: 250px;
      height: 500px;
    }
    .download-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 249.333px;
      height: 249.333px;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      box-sizing: border-box;
      padding-top: 40px;
      position: relative;
      &:nth-child(3) {
        border-right: 0;
      }
      &:nth-child(6) {
        border-right: 0;
        border-bottom: 0;
      }
      &:nth-child(4) {
        border-bottom: 0;
      }
      &:nth-child(5) {
        border-bottom: 0;
      }
      @media screen and (max-width: 1000px) {
        &:nth-child(2) {
          border-right: 0;
        }
        &:nth-child(3) {
          border-right: 1px solid #ccc;
        }
        &:nth-child(4) {
          border-right: 0;
          border-bottom: 1px solid #ccc;
        }
        &:nth-child(5) {
          border-bottom: 0;
        }
        &:nth-child(6) {
          border-right: 0;
          border-bottom: 0;
        }
      }
      @media screen and (max-width: 749px) {
        display: none;
        &:nth-child(5) {
          border-bottom: 1px solid #ccc;
          display: flex;
        }
        &:nth-child(6) {
          display: flex;
          border-bottom: 0;
        }
      }
      .download-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        .download-icon {
          width: 70px;
          height: 70px;
        }
        .download-platform {
          font-size: 20px;
          font-weight: 500;
          margin-top: 30px;
        }
      }
      .hover-main {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
        box-sizing: border-box;
        padding-top: 35px;
        .hover-img {
          width: 220px;
          height: 130px;
          transition: all 0.2s linear;
          transform: scale(0.7, 0.7);
        }
        &:hover {
          opacity: 1;
          border-radius: 20px;
          .hover-img {
            transform: scale(1, 1);
          }
        }
      }
      .download-btn {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 38px;
        width: 100px;
        height: 32px;
        border-radius: 20px;
        font-size: 14px;
        color: #666;
        background: rgba(51, 51, 51, 0.05);
        margin-top: 20px;
        z-index: 0;
        @media screen and (max-width: 749px) {
          z-index: 3;
        }
        &:hover {
          cursor: pointer;
          background: linear-gradient(90deg, #3caffe 0%, #1069ef 100%);
          color: #fff;
        }
      }
      .download-hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        opacity: 0;
        z-index: 2;
        transition: all 0.2s linear;
        transform: scale(0.7, 0.7);
        box-sizing: border-box;
        padding-top: 30px;
        cursor: pointer;
        &:hover {
          border-radius: 20px;
          transform: scale(1, 1);
          opacity: 1;
        }
        @media screen and (max-width: 749px) {
          &:hover {
            transform: scale(1, 1);
            opacity: 0;
          }
        }
        .code-img {
          width: 130px;
          height: 130px;
        }
        .code-sao {
          font-weight: 500;
          margin-top: 15px;
        }
        .code-name {
          margin-top: 15px;
        }
        .hover-img {
          width: 220px;
          height: 130px;
        }
      }
    }
  }
}
</style>
