<template>
  <div id="second-screen" class="second-screen">
    <img
      v-show="!isFixed"
      class="fixed-bg"
      src="../../assets/images/first-screen-bg.jpg"
      alt=""
    />
    <img class="second-bg" src="../../assets/images/second-bg.png" alt="" />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang='scss'>
.second-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 1000px) {
    height: auto;
    padding-top: 130px;
    padding-bottom: 107px;
  }
  @media screen and (max-width: 749px) {
    height: auto;
    padding-top: 150px;
    padding-bottom: 109px;
  }
  .second-bg {
    width: 1040px;
    height: 620px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 1299px) {
      width: 800px;
      height: 480px;
    }
    @media screen and (max-width: 1000px) {
      width: 78.125%;
      height: auto;
    }
    @media screen and (max-width: 749px) {
      width: 93%;
      height: auto;
    }
  }
  .fixed-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 0;
    @media screen and (max-width: 1000px) {
      height: 100%;
    }
  }
}
</style>