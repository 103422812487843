<template>
  <WebDialog ref="webDialog" :dialogWidth="dialogWidth" headerText="版本对比">
    <div class="dialog-main">
      <div class="main-left">
        <div class="left-1">配置及功能</div>
        <div class="left-2">
          <div class="item">店铺数量</div>
          <div class="item">成员数量</div>
          <div class="item">标配云空间</div>
        </div>
        <div class="left-2 left-3">
          <div class="item">全屋智能项目管理</div>
          <div class="item">售后服务管理</div>
          <div class="item">权限控制</div>
          <div class="item">离职工作交接</div>
          <div class="item">项目内部转让</div>
          <div class="item">数据统计与报表</div>
          <div class="item">服务链支持</div>
          <div class="item">专属二级域名</div>
          <div class="item">品牌logo显示</div>
          <div class="item">开放平台API</div>
          <div class="item">专人技术支持</div>
        </div>
      </div>
      <div class="main-item">
        <div class="item-header">
          <div class="top">标准版</div>
          <div class="bottom">永久免费</div>
        </div>
        <div class="item-middle">
          <div class="middle-item">1个</div>
          <div class="middle-item">20人</div>
          <div class="middle-item">10GB</div>
        </div>
        <div class="item-bottom">
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="bottom-item">单店数据统计与报表</div>
          <div class="el-icon-close bottom-item"></div>
          <div class="el-icon-close bottom-item"></div>
          <div class="el-icon-close bottom-item"></div>
          <div class="el-icon-close bottom-item"></div>
          <div class="el-icon-close bottom-item"></div>
        </div>
      </div>
      <div class="main-item">
        <div class="item-header">
          <div class="top">专业版</div>
          <div class="bottom">1000 元/店铺/年</div>
        </div>
        <div class="item-middle">
          <div class="middle-item">1~10个</div>
          <div class="middle-item">300人</div>
          <div class="middle-item">100GB~1TB</div>
        </div>
        <div class="item-bottom">
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="bottom-item">总店数据统计与报表</div>
          <div class="el-icon-close bottom-item"></div>
          <div class="el-icon-close bottom-item"></div>
          <div class="el-icon-close bottom-item"></div>
          <div class="el-icon-close bottom-item"></div>
          <div class="el-icon-close bottom-item"></div>
        </div>
      </div>
      <div class="main-item">
        <div class="item-header">
          <div class="top">集团版</div>
          <div class="bottom">按需定制</div>
        </div>
        <div class="item-middle">
          <div class="middle-item">无限制</div>
        </div>
        <div class="item-bottom">
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="bottom-item">集团数据统计与报表</div>
          <div class="bottom-item">第一方服务</div>
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
          <div class="el-icon-check bottom-item"></div>
        </div>
      </div>
    </div>
  </WebDialog>
</template>

<script>
import WebDialog from "@/components/Webdialog";
export default {
  data() {
    return {
      dialogShow: false,
      dialogWidth: "800px",
    };
  },
  components: {
    WebDialog,
  },
  created(){
    window.addEventListener('resize',this.windowResize);
  },
  beforeDestroy(){
    window.removeEventListener('resize',this.windowResize);
  },
  methods: {
    windowResize(){
      if(window.innerWidth <= 1000 && window.innerWidth >=750){
        this.dialogWidth = '660px';
      }else if(window.innerWidth > 1000){
        this.dialogWidth = '800px';
      }else{
        this.dialogWidth = window.innerWidth - 20 + 'px';
      }
    },
    // 控制dialog的显示和隐藏
    controlDialog() {
      this.$refs["webDialog"].controlDialog();
      if (window.innerWidth <= 1000 && window.innerWidth >= 750) {
        this.dialogWidth = "660px";
      } else if (window.innerWidth > 1000) {
        this.dialogWidth = "800px";
      } else {
        this.dialogWidth = window.innerWidth - 20 + "px";
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.dialog-main {
  width: 100%;
  max-height: calc(100vh - 250px);
  overflow: scroll;
  @media screen and (max-width: 749px) {
    max-height: calc(100vh - 250px);
  }
  min-height: 300px;
  box-sizing: border-box;
  display: flex;
  .main-left {
    height: 100%;
    width: 320px;
    border-right: 0.5px solid #ccc;
    @media screen and (max-width: 1000px) {
      width: 180px;
    }
    @media screen and (max-width: 749px) {
      width: 30%;
    }
    .left-1 {
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 50px;
      font-size: 18px;
      font-weight: 500;
      @media screen and (max-width: 749px) {
        padding-left: 15px;
        height: 90px;
        font-size: 16px;
      }
    }
    .left-2 {
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
      padding-left: 50px;
      font-size: 14px;
      min-height: 117px;
      border-top: 0.5px solid #ccc;
      @media screen and (max-width: 749px) {
        padding-left: 15px;
      }
      .item {
        margin-bottom: 18px;
        @media screen and (max-width: 749px) {
          line-height: 18px;
          margin-bottom: 16px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .left-3 {
      .item {
        @media screen and (max-width: 749px) {
          min-height: 54px;
          margin-bottom: 0;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .main-item {
    height: 100%;
    border-right: 0.5px solid #ccc;
    width: calc((100% - 320px) / 3);
    @media screen and (max-width: 1000px) {
      width: calc((100% - 180px) / 3);
    }
    @media screen and (max-width: 749px) {
      width: calc((100% - 30%) / 3);
    }
    &:last-child {
      border-right: 0;
    }
    .item-header {
      width: 100%;
      height: 70px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 15px;
      @media screen and (max-width: 749px) {
        height: 90px;
        padding-top: 20px;
      }
      .top {
        font-size: 18px;
        font-weight: 500;
        @media screen and (max-width: 749px) {
          font-size: 16px;
        }
      }
      .bottom {
        font-size: 14px;
        margin-top: 7px;
        line-height: 20px;
        text-align: center;
      }
    }
    .item-middle {
      width: 100%;
      padding: 15px 0;
      min-height: 117px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-top: 0.5px solid #ccc;
      .middle-item {
        font-size: 14px;
        margin-bottom: 18px;
        @media screen and (max-width: 749px) {
          line-height: 18px;
          margin-bottom: 16px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .item-bottom {
      width: 100%;
      padding: 15px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-top: 0.5px solid #ccc;
      .bottom-item {
        margin-bottom: 16px;
        @media screen and (max-width: 749px) {
          line-height: 18px;
          padding: 0 9px;
          min-height: 54px;
          display: flex;
          align-items: center;
          margin-bottom: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      .el-icon-check {
        color: #268ff8;
        font-size: 16px;
      }
      .el-icon-close {
        font-size: 16px;
        color: #ccc;
      }
    }
  }
}
</style>