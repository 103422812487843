<template>
  <div class="all-bg">
    <img
      v-show="isFixed"
      class="fixed-bg"
      src="../../assets/images/first-screen-bg.jpg"
      alt=""
    />
    <img
      v-show="isFixed"
      class="fixed-bg1"
      src="../../assets/images/first-bg-screen.jpg"
      alt=""
    />
    <div class="first-screen d-flex-center">
      <div class="first-middle">
        <div class="brand-name">
          <img class="brand-logo" src="../../assets/images/logo.png" alt="" />
          <span class="brand-text">和慕PMS</span>
        </div>
        <div class="brand-desc">全屋智能的项目管理生产力工具</div>
        <div class="brand-handle d-flex-center">
          <a
            target="_blank"
            href="https://web2.homepms.com"
            class="enter-btn d-flex-center"
            >进入网页版</a
          >
          <div
            @click="toPagePlace('download')"
            class="download-btn d-flex-center"
          >
            立即下载
          </div>
        </div>
        <img class="san-left" src="../../assets/images/san-left.png" alt="" />
        <img class="san-right" src="../../assets/images/san-right.png" alt="" />
        <img class="san-big" src="../../assets/images/san-big.png" alt="" />
      </div>
    </div>
    <SecondScreen ref="secondScreen" :isFixed="isFixed"></SecondScreen>
  </div>
</template>

<script>
import SecondScreen from "./secondScreen";
export default {
  props: {
    isFixed: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    SecondScreen,
  },
  data() {
    return {};
  },
  methods: {
    toPagePlace(url) {
      let topHeight = 0;
      if (url === "download") {
        topHeight =
          document.getElementById("download").getBoundingClientRect().top +
          document.documentElement.scrollTop;
      } else if (url == "price") {
        topHeight =
          document.getElementById("price").getBoundingClientRect().top +
          document.documentElement.scrollTop;
      }
      this.fnc_scrollto(topHeight);
    },
    fnc_scrollto(to, id) {
      var smoothScrollFeature =
        "scrollBehavior" in document.documentElement.style;
      var articles = document.querySelectorAll("ul#content > li");
      if (to == "elem") to = articles[id].offsetTop;
      var i = parseInt(window.pageYOffset);
      if (i != to) {
        if (!smoothScrollFeature) {
          to = parseInt(to);
          if (i < to) {
            let int = setInterval(function () {
              if (i > to - 20) i += 1;
              else if (i > to - 40) i += 3;
              else if (i > to - 80) i += 8;
              else if (i > to - 160) i += 18;
              else if (i > to - 200) i += 24;
              else if (i > to - 300) i += 40;
              else i += 60;
              window.scroll(0, i);
              if (i >= to) clearInterval(int);
            }, 15);
          } else {
            let int = setInterval(function () {
              if (i < to + 20) i -= 1;
              else if (i < to + 40) i -= 3;
              else if (i < to + 80) i -= 8;
              else if (i < to + 160) i -= 18;
              else if (i < to + 200) i -= 24;
              else if (i < to + 300) i -= 40;
              else i -= 60;
              window.scroll(0, i);
              if (i <= to) clearInterval(int);
            }, 15);
          }
        } else {
          window.scroll({
            top: to,
            left: 0,
            behavior: "smooth",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.all-bg {
  position: relative;
  @media screen and (max-width: 1000px) {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-attachment: scroll;
    box-sizing: border-box;
  }
  @media screen and (max-width: 749px) {
    height: auto;
  }
}
.first-screen {
  width: 100%;
  height: 100vh;
  position: relative;
  padding-top: 70px;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  @media screen and (max-width: 1000px) {
    height: auto;
    padding-top: 177px;
  }
  .first-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .first-middle {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .brand-name {
      display: flex;
      align-items: center;
      .brand-logo {
        width: 100px;
        height: 100px;
        @media screen and (max-width: 1299px) {
          width: 80px;
          height: 80px;
        }
        @media screen and (max-width: 1000px) {
          width: 70px;
          height: 70px;
        }
        @media screen and (max-width: 749px) {
          width: 54px;
          height: 54px;
        }
      }
      .brand-text {
        font-size: 66px;
        font-weight: 500;
        color: #146de9;
        margin-left: 35px;
        @media screen and (max-width: 1299px) {
          font-size: 52px;
          margin-left: 25px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 44px;
          margin-left: 20px;
        }
        @media screen and (max-width: 749px) {
          font-size: 32px;
          margin-left: 15px;
        }
      }
    }
    .brand-desc {
      font-size: 66px;

      margin-top: 30px;
      font-weight: 500;
      text-align: center;
      @media screen and (max-width: 1299px) {
        font-size: 52px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 44px;
        line-height: 52px;
      }
      @media screen and (max-width: 749px) {
        font-size: 32px;
        line-height: 48px;
        margin-top: 22px;
      }
    }
    .brand-handle {
      display: flex;
      align-items: center;
      font-size: 20px;
      margin-top: 80px;
      @media screen and (max-width: 1000px) {
        margin-top: 50px;
      }
      @media screen and (max-width: 749px) {
        display: none;
      }
      .enter-btn {
        width: 160px;
        height: 56px;
        background: linear-gradient(to bottom, #3caffe 0%, #1069ef 100%);
        border-radius: 30px;
        color: #fff;
        &:hover {
          cursor: pointer;
        }
      }
      .download-btn {
        margin-left: 50px;
        width: 160px;
        height: 56px;
        border-radius: 30px;
        color: #268ff8;
        border: 1px solid #fff;
        background: rgba(255, 255, 255, 0.39);
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        &:hover {
          cursor: pointer;
        }
      }
    }
    .san-left {
      position: absolute;
      width: 44px;
      height: 44px;
      left: -38px;
      bottom: -22px;
      @media screen and (max-width: 1000px) {
        display: none;
      }
      @media screen and (max-width: 749px) {
        display: block;
        width: 24px;
        height: 24px;
        bottom: -84px;
        left: 9px;
      }
    }
    .san-right {
      position: absolute;
      width: 44px;
      height: 44px;
      bottom: -38px;
      right: -28px;
      @media screen and (max-width: 1000px) {
        display: none;
      }
      @media screen and (max-width: 749px) {
        display: block;
        width: 24px;
        height: 24px;
        bottom: -67px;
        right: 10px;
      }
    }
    .san-big {
      position: absolute;
      width: 220px;
      height: 130px;
      left: 111px;
      bottom: -30px;
      transform: translateY(100%);
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
  }
}

.fixed-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  @media screen and (max-width: 1000px) {
    position: absolute;
  }
  @media screen and (max-width: 749px) {
    display: none;
  }
}
.fixed-bg1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: none;
  @media screen and (max-width: 749px) {
    position: absolute;
    display: block;
  }
}
</style>