<template>
  <div class="user-rating">
    <div class="user-header">用户评价</div>
    <div class="user-tip">选自日常反馈和问卷调查的真实用户评价</div>
    <div class="rate-outer">
      <div
        class="rate-main"
        :style="{transform:`translate(${-scrollLeft}px,0)`}"
      >
        <div
          class="rate-shu"
          v-for="(outerItem, outerIndex) in newRateList"
          :key="outerIndex"
        >
          <div
            class="rate-item"
            v-for="(item, index) in outerItem"
            :key="index"
          >
            <div class="item-top">
              <img
                class="user-avatar"
                :src="require(`../../assets/images/rate-${item.value}.png`)"
                alt=""
              />
              <span class="user-name">{{ item.username }}</span>
            </div>
            <div class="item-bottom">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let timer = null;
export default {
  data() {
    return {
      scrollLeft: 0, //列表滚动宽度
      speed: 20, //滚动速度
      newRateList: [
        [
          {
            username: "夏目友人",
            value: 1,
            text: "挺好用的，文件管理做得不错",
          },
          {
            username: "暮色",
            value: 6,
            text: "售后管理是我一直想要的功能，以后管理售后问题就方便多啦",
          },
        ],
        [
          {
            username: "rainbow",
            value: 2,
            text: "体验了一下，项目从立项到交付、售后全流程管理，可以呀",
          },
          {
            username: "小晴天",
            value: 7,
            text: "功能强大，对项目和人员管理起来都很方便",
          },
        ],
        [
          {
            username: "SlowLee",
            value: 3,
            text: "数据统计这一块挺好，各种图表显示很直观",
          },
          {
            username: "暖暖阳光min",
            value: 8,
            text: "作为管理者，这个软件真的给自己节省了不少时间和精力",
          },
        ],
        [
          {
            username: "JinyunC",
            value: 4,
            text: "体验了手机、平板和电脑不同版本，功能挺全了，希望可以开发更多新功能",
          },
          {
            username: "胡萝卜君",
            value: 9,
            text: "操作历史记录什么的都有，不错，继续用用看",
          },
        ],
        [
          {
            username: "丹青吃薄荷",
            value: 5,
            text: "人员管理、职责交接、售后管理功能都不错",
          },
          {
            username: "小雅",
            value: 10,
            text: "终于有项目转让功能了，这对一些外包项目来说真的很重要",
          },
        ],
        [
          {
            username: "夏目友人",
            value: 1,
            text: "挺好用的，文件管理做得不错",
          },
          {
            username: "暮色",
            value: 6,
            text: "售后管理是我一直想要的功能，以后管理售后问题就方便多啦",
          },
        ],
        [
          {
            username: "rainbow",
            value: 2,
            text: "体验了一下，项目从立项到交付、售后全流程管理，可以呀",
          },
          {
            username: "小晴天",
            value: 7,
            text: "功能强大，对项目和人员管理起来都很方便",
          },
        ],
        [
          {
            username: "SlowLee",
            value: 3,
            text: "数据统计这一块挺好，各种图表显示很直观",
          },
          {
            username: "暖暖阳光min",
            value: 8,
            text: "作为管理者，这个软件真的给自己节省了不少时间和精力",
          },
        ],
        [
          {
            username: "JinyunC",
            value: 4,
            text: "体验了手机、平板和电脑不同版本，功能挺全了，希望可以开发更多新功能",
          },
          {
            username: "胡萝卜君",
            value: 9,
            text: "操作历史记录什么的都有，不错，继续用用看",
          },
        ],
        [
          {
            username: "丹青吃薄荷",
            value: 5,
            text: "人员管理、职责交接、售后管理功能都不错",
          },
          {
            username: "小雅",
            value: 10,
            text: "终于有项目转让功能了，这对一些外包项目来说真的很重要",
          },
        ],
        [
          {
            username: "夏目友人",
            value: 1,
            text: "挺好用的，文件管理做得不错",
          },
          {
            username: "暮色",
            value: 6,
            text: "售后管理是我一直想要的功能，以后管理售后问题就方便多啦",
          },
        ],
        [
          {
            username: "rainbow",
            value: 2,
            text: "体验了一下，项目从立项到交付、售后全流程管理，可以呀",
          },
          {
            username: "小晴天",
            value: 7,
            text: "功能强大，对项目和人员管理起来都很方便",
          },
        ],
        [
          {
            username: "SlowLee",
            value: 3,
            text: "数据统计这一块挺好，各种图表显示很直观",
          },
          {
            username: "暖暖阳光min",
            value: 8,
            text: "作为管理者，这个软件真的给自己节省了不少时间和精力",
          },
        ],
        [
          {
            username: "JinyunC",
            value: 4,
            text: "体验了手机、平板和电脑不同版本，功能挺全了，希望可以开发更多新功能",
          },
          {
            username: "胡萝卜君",
            value: 9,
            text: "操作历史记录什么的都有，不错，继续用用看",
          },
        ],
        [
          {
            username: "丹青吃薄荷",
            value: 5,
            text: "人员管理、职责交接、售后管理功能都不错",
          },
          {
            username: "小雅",
            value: 10,
            text: "终于有项目转让功能了，这对一些外包项目来说真的很重要",
          },
        ],
        [
          {
            username: "夏目友人",
            value: 1,
            text: "挺好用的，文件管理做得不错",
          },
          {
            username: "暮色",
            value: 6,
            text: "售后管理是我一直想要的功能，以后管理售后问题就方便多啦",
          },
        ],
        [
          {
            username: "rainbow",
            value: 2,
            text: "体验了一下，项目从立项到交付、售后全流程管理，可以呀",
          },
          {
            username: "小晴天",
            value: 7,
            text: "功能强大，对项目和人员管理起来都很方便",
          },
        ],
        [
          {
            username: "SlowLee",
            value: 3,
            text: "数据统计这一块挺好，各种图表显示很直观",
          },
          {
            username: "暖暖阳光min",
            value: 8,
            text: "作为管理者，这个软件真的给自己节省了不少时间和精力",
          },
        ],
        [
          {
            username: "JinyunC",
            value: 4,
            text: "体验了手机、平板和电脑不同版本，功能挺全了，希望可以开发更多新功能",
          },
          {
            username: "胡萝卜君",
            value: 9,
            text: "操作历史记录什么的都有，不错，继续用用看",
          },
        ],
        [
          {
            username: "丹青吃薄荷",
            value: 5,
            text: "人员管理、职责交接、售后管理功能都不错",
          },
          {
            username: "小雅",
            value: 10,
            text: "终于有项目转让功能了，这对一些外包项目来说真的很重要",
          },
        ],
        [
          {
            username: "夏目友人",
            value: 1,
            text: "挺好用的，文件管理做得不错",
          },
          {
            username: "暮色",
            value: 6,
            text: "售后管理是我一直想要的功能，以后管理售后问题就方便多啦",
          },
        ],
        [
          {
            username: "rainbow",
            value: 2,
            text: "体验了一下，项目从立项到交付、售后全流程管理，可以呀",
          },
          {
            username: "小晴天",
            value: 7,
            text: "功能强大，对项目和人员管理起来都很方便",
          },
        ],
        [
          {
            username: "SlowLee",
            value: 3,
            text: "数据统计这一块挺好，各种图表显示很直观",
          },
          {
            username: "暖暖阳光min",
            value: 8,
            text: "作为管理者，这个软件真的给自己节省了不少时间和精力",
          },
        ],
        [
          {
            username: "JinyunC",
            value: 4,
            text: "体验了手机、平板和电脑不同版本，功能挺全了，希望可以开发更多新功能",
          },
          {
            username: "胡萝卜君",
            value: 9,
            text: "操作历史记录什么的都有，不错，继续用用看",
          },
        ],
        [
          {
            username: "丹青吃薄荷",
            value: 5,
            text: "人员管理、职责交接、售后管理功能都不错",
          },
          {
            username: "小雅",
            value: 10,
            text: "终于有项目转让功能了，这对一些外包项目来说真的很重要",
          },
        ],
        [
          {
            username: "夏目友人",
            value: 1,
            text: "挺好用的，文件管理做得不错",
          },
          {
            username: "暮色",
            value: 6,
            text: "售后管理是我一直想要的功能，以后管理售后问题就方便多啦",
          },
        ],
        [
          {
            username: "rainbow",
            value: 2,
            text: "体验了一下，项目从立项到交付、售后全流程管理，可以呀",
          },
          {
            username: "小晴天",
            value: 7,
            text: "功能强大，对项目和人员管理起来都很方便",
          },
        ],
        [
          {
            username: "SlowLee",
            value: 3,
            text: "数据统计这一块挺好，各种图表显示很直观",
          },
          {
            username: "暖暖阳光min",
            value: 8,
            text: "作为管理者，这个软件真的给自己节省了不少时间和精力",
          },
        ],
        [
          {
            username: "JinyunC",
            value: 4,
            text: "体验了手机、平板和电脑不同版本，功能挺全了，希望可以开发更多新功能",
          },
          {
            username: "胡萝卜君",
            value: 9,
            text: "操作历史记录什么的都有，不错，继续用用看",
          },
        ],
        [
          {
            username: "丹青吃薄荷",
            value: 5,
            text: "人员管理、职责交接、售后管理功能都不错",
          },
          {
            username: "小雅",
            value: 10,
            text: "终于有项目转让功能了，这对一些外包项目来说真的很重要",
          },
        ]
      ],

    };
  },
  mounted() {
    this.initScroll();
  },
  beforeDestroy() {
    clearInterval(timer);
  },
  methods: {
    initScroll() {
      this.$nextTick(() => {
        this.startScroll();
      });
    },
    // 鼠标移入停止滚动
    mEnter() {
      clearInterval(timer);
    },
    // 鼠标移出继续滚动
    mLeave() {
      this.startScroll();
    },
    // 开始滚动
    startScroll() {
      timer = setInterval(this.scroll, this.speed);
    },
    // 滚动处理方法
    scroll() {
      this.scrollLeft++;
      // 获取需要滚动的盒子的宽度
      // 当判断滚动的宽度大于盒子宽度的时候，从头开始滚动
      if (this.scrollLeft >= 2400) {
        this.scrollLeft = 0;
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.user-rating {
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .user-header {
    font-size: 44px;
    font-weight: 500;
    @media screen and (max-width: 1299px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 36px;
    }
    @media screen and (max-width: 749px) {
      font-size: 32px;
    }
  }
  .user-tip {
    font-size: 16px;
    color: #666;
    margin-top: 20px;
    padding: 0 44px;
    margin-bottom: 50px;
    @media screen and (max-width: 749px) {
      margin-bottom: 40px;
    }
  }
  .rate-outer {
    width: 100%;
    overflow: hidden;
  }
  .rate-main {
    display: flex;
    .rate-shu {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
    }
    .rate-item {
      width: 450px;
      padding: 25px 35px 26px;
      box-sizing: border-box;
      border-radius: 20px;
      background: #fff;
      box-shadow: 5px 5px 10px rgba(47, 124, 191, 0.1);
      margin-bottom: 30px;
      text-align: left;
      float: left;
      @media screen and (max-width: 1299px) {
        width: 400px;
      }
      @media screen and (max-width: 749px) {
        width: 83.5vw;
        padding-right: 33px;
      }

      .item-top {
        display: flex;
        align-items: center;
        .user-avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .user-name {
          font-size: 18px;
          font-weight: 500;
          margin-left: 10px;
          @media screen and (max-width: 749px) {
            margin-left: 1px;
          }
        }
      }
      .item-bottom {
        margin-top: 11px;
        font-size: 16px;
        color: #666;
        line-height: 24px;
      }
    }
  }

  @keyframes carousel {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translatex(-100%);
    }
  }
}
</style>