<template>
  <div id="third-screen" class="third-screen">
    <div class="third-header">管理好您的全屋智能项目，只是开始</div>
    <div class="third-main">
      <img class="third-bg" src="../../assets/images/third-bg.png" alt="" />
      <div class="third-list">
        <div class="list-item">
          <img class="item-num" src="../../assets/images/num-1.png" alt="" />
          <div class="item-block">
            <div class="block-top">
              <span class="order-num">1、</span><span>不怕一线员工上手难</span>
            </div>
            <div class="block-bottom">
              操作简单，学习成本低，员工上手快
              <br />
              全平台支持，随时随地移动办公
            </div>
          </div>
        </div>
        <div class="list-item">
          <img class="item-num" src="../../assets/images/num-2.png" alt="" />
          <div class="item-block">
            <div class="block-top">
              <span class="order-num">2、</span><span>不怕项目管理很混乱</span>
            </div>
            <div class="block-bottom">
              项目进度实时跟踪，标准化验收和交付
              <br />
              数据统计和报表完善，随时掌握全局
            </div>
          </div>
        </div>
        <div class="list-item">
          <img class="item-num" src="../../assets/images/num-3.png" alt="" />
          <div class="item-block">
            <div class="block-top">
              <span class="order-num">3、</span><span>不怕重要文档找不到</span>
            </div>
            <div class="block-bottom">
              文件实时共享，提高协同办公效率<br />
              文档集中管理，统一管理文件夹结构和权限
            </div>
          </div>
        </div>
        <div class="list-item">
          <img class="item-num" src="../../assets/images/num-4.png" alt="" />
          <div class="item-block">
            <div class="block-top">
              <span class="order-num">4、</span><span>不怕工作交接没头绪</span>
            </div>
            <div class="block-bottom">
              团队权限设置，细化企业管理<br />
              工作交接项目权限自动迁移，不丢进度
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped lang='scss'>
.third-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 100px;
  @media screen and (max-width: 1299px) {
    padding-top: 100px;
  }
  @media screen and (max-width: 1000px) {
    padding-bottom: 80px;
  }
  @media screen and (max-width: 749px) {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .third-header {
    font-size: 44px;
    font-weight: 500;
    @media screen and (max-width: 1299px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 36px;
    }
    @media screen and (max-width: 749px) {
      font-size: 28px;
      line-height: 44px;
      padding: 0 58px;
      text-align: center;
    }
  }
  .third-main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    flex-wrap: wrap;
    @media screen and (max-width: 1000px) {
      margin-top: 50px;
      flex-direction: column;
    }
    @media screen and (max-width: 749px) {
      margin-top: 22px;
    }
    .third-bg {
      width: 560px;
      height: 560px;
      @media screen and (max-width: 1299px) {
        width: 420px;
        height: 420px;
      }
      @media screen and (max-width: 749px) {
        width: 300px;
        height: 300px;
      }
    }
    .third-list {
      margin-left: 50px;
      @media screen and (max-width: 1299px) {
        margin-left: 52px;
      }
      @media screen and (max-width: 1000px) {
        margin-left: 0;
      }
      @media screen and (max-width: 749px) {
        margin-top: 10px;
      }
      .list-item {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        .item-num {
          width: 50px;
          height: 80px;
          @media screen and (max-width: 1299px) {
            width: 42px;
            height: 64px;
          }
          @media screen and (max-width: 1000px) {
            width: 36px;
            height: 64px;
          }
          @media screen and (max-width: 749px) {
            display: none;
          }
        }
        .item-block {
          width: 420px;
          height: 132px;
          padding: 25px 35px 0;
          box-sizing: border-box;
          border-radius: 15px;
          box-shadow: 0px 5px 20px rgba(47, 124, 191, 0.1);
          background: #fff;
          margin-left: 40px;
          &:hover {
            cursor: pointer;
            filter: drop-shadow(10px 10px 10px rgba(47, 124, 191, 0.1));
            transform: translateZ(50px) translateY(-12px);
            -webkit-transform: translateZ(50px) translateY(-12px);
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
          }
          @media screen and (max-width: 1299px) {
            width: 330px;
            height: 132px;
            padding-left: 25px;
            margin-left: 43px;
          }
          @media screen and (max-width: 1000px) {
            width: 65vw;
            height: 132px;
            padding-left: 25px;
            padding-right: 25px;
            margin-left: 46px;
          }
          @media screen and (max-width: 749px) {
            width: 85.3vw;
            height: auto;
            padding-top: 25px;
            padding-left: 25px;
            padding-right: 25px;
            padding-bottom: 19px;
            margin-left: 0px;
          }
          .block-top {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 14px;
            @media screen and (max-width: 749px) {
              font-size: 18px;
            }
            .order-num {
              display: none;
              @media screen and (max-width: 749px) {
                display: inline;
              }
            }
          }
          .block-bottom {
            font-size: 14px;
            color: #666;
            line-height: 26px;
          }
        }
      }
    }
  }
}
</style>