<template>
  <div @click="closeMenuList">
    <NavigatorHeader ref="navigatorHeader"/>
    <FirstScreen :isFixed="isFixed"/>
    <ThirdScreen/>
    <ForthScreen/>
    <FifthScreen/>
    <UserRating/>
    <Download/>
    <Price/>
    <About/>
    <DataPrivacy/>
    <CommonFooter/>
  </div>
</template>

<script>
import NavigatorHeader from '@/components/NavigatorHeader';
import FirstScreen from './firstScreen';
import ThirdScreen from './thirdScreen';
import ForthScreen from './forthScreen';
import FifthScreen from './fifthScreen';
import UserRating from './userRating';
import Download from './download';
import Price from './price';
import About from './about';
import DataPrivacy from './dataPrivacy';
import CommonFooter from '@/components/CommonFooter';
export default {
  components: {
    NavigatorHeader,
    FirstScreen,
    ThirdScreen,
    ForthScreen,
    FifthScreen,
    UserRating,
    Download,
    Price,
    About,
    DataPrivacy,
    CommonFooter
  },
  data () {
    return {
      isFixed:true
    };
  },
  created() {
    window.addEventListener("scroll", this.appScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.appScroll);
  },
  methods:{
    appScroll() {
      // console.log(document.getElementById('second-screen').getBoundingClientRect().top);
      if(window.innerWidth<=1000){
        this.isFixed = true;
        return;
      }
      if(document.getElementById('second-screen').getBoundingClientRect().top <=70){
        this.isFixed = false;
      }else{
        this.isFixed = true;
      }
    },
    closeMenuList(){
      if(this.$refs['navigatorHeader']){
        this.$refs['navigatorHeader'].closeMenuList();
      }
    }
  }
};
</script>

<style scoped lang='scss'>

</style>