<template>
  <div class="data-privacy">
    <div class="data-header">数据隐私安全</div>
    <div class="tips">
      作为和慕PMS的开发运营商，我们非常重视并尽全力保护您的个人信息和数据隐私。
    </div>
    <div class="tips">
      我们不会出售您的信息和数据，也不会帮助第三方公司使用您的个人信息来推广产品。和慕PMS盈利的唯一方式是，当您购买和慕PMS服务时收取的订阅费用。
    </div>
    <div class="tips">
      我们采用业界主流的加密方式，在数据传输和存储全过程中保护数据安全。每个用户仅能访问自己拥有的文档。完整的隐私政策请参见<span @click="$refs['privacyPolicy'].controlDialog()" class="tips-docu">《和慕PMS隐私协议》</span>。
    </div>
    <PrivacyPolicy headerText="和慕PMS隐私协议" ref="privacyPolicy" />
  </div>
</template>

<script>
import PrivacyPolicy from "@/components/PrivacyPolicy";
export default {
  components:{
    PrivacyPolicy
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang='scss'>
.data-privacy {
  width: 100%;
  padding: 60px 0;
  padding-bottom: 40px;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 749px) {
    padding: 50px 0;
    padding-bottom: 45px;
  }
  .data-header {
    font-size: 44px;
    font-weight: 500;
    margin-bottom: 26px;
    @media screen and (max-width: 1299px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 36px;
    }
    @media screen and (max-width: 749px) {
      font-size: 32px;
    }
  }
  .tips {
    font-size: 14px;
    color: #666;
    margin-bottom: 16px;
    line-height: 22px;
    .tips-docu{
      color: #268ff8;
      &:hover{
        cursor: pointer;
      }
    }
    @media screen and (max-width: 1299px) {
      max-width: 700px;
      text-align: center;
    }
    @media screen and (max-width: 1000px) {
      max-width: 600px;
    }
    @media screen and (max-width: 749px) {
      max-width: 275px;
      margin-bottom: 20px;
    }
  }
}
</style>