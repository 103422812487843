<template>
  <div id="price" class="price">
    <div class="price-header">定价</div>
    <div class="price-tip">标准版免费使用，后续可无缝升级专业版</div>
    <div class="price-main">
      <div @click="showVersionCompare" class="version-compare">
        <span>版本对比</span>
        <span class="el-icon-arrow-right"></span>
      </div>
      <div class="price-item">
        <div class="item-name">标准版</div>
        <div class="item-desc">
          单店铺使用，畅享项目管理、文档管理、团队管理、数据统计、第三方服务等全部核心功能，标配10GB云空间
        </div>
        <div @click="showVersionCompare" class="item-look">
          <span>查看详情</span>
          <span class="el-icon-arrow-right"></span>
        </div>
        <div class="item-free">永久免费</div>
        <a href="https://web2.homepms.com" target="_blank" class="item-btn d-flex-center">立即体验</a>
      </div>
      <div class="price-item">
        <div class="item-name">专业版</div>
        <div class="item-desc">
          总部+多分店架构，畅享项目管理、文档管理、团队管理、数据统计、第三方服务等全部核心功能，标配100GB云空间
        </div>
        <div @click="showVersionCompare" class="item-look">
          <span>查看详情</span>
          <span class="el-icon-arrow-right"></span>
        </div>
        <div class="item-free">
          <span>1000元</span>
          <span style="color: #333">/店铺/年</span>
        </div>
        <a href="https://web2.homepms.com" target="_blank" class="item-btn1 d-flex-center">立即购买</a>
      </div>
      <div class="price-item">
        <div class="item-name">集团版</div>
        <div class="item-desc">
          多级组织架构，跨部门协同，集团服务，应用内全部功能，高级功能及定制化集团企业需求，不限量云空间
        </div>
        <div @click="showVersionCompare" class="item-look">
          <span>查看详情</span>
          <span class="el-icon-arrow-right"></span>
        </div>
        <div class="item-free item-free1">
          按需定制
        </div>
        <div
          @click="$router.push('/businessCooperation')"
          class="item-btn2 d-flex-center"
        >
          联系我们
        </div>
      </div>
    </div>
    <VersionCompare ref="versionCompare" />
  </div>
</template>

<script>
import VersionCompare from "@/components/VersionCompare";
export default {
  data() {
    return {};
  },
  components: {
    VersionCompare,
  },
  methods: {
    showVersionCompare() {
      this.$refs["versionCompare"].controlDialog();
    },
  },
};
</script>

<style scoped lang='scss'>
.price {
  width: 100%;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1299px) {
    padding-top: 100px;
  }
  .price-header {
    font-size: 44px;
    font-weight: 500;
    @media screen and (max-width: 1299px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 36px;
    }
    @media screen and (max-width: 749px) {
      font-size: 32px;
    }
  }
  .price-tip {
    font-size: 16px;
    color: #666;
    margin-top: 28px;
    @media screen and (max-width: 749px) {
      margin-top: 20px;
      text-align: center;
    }
  }
  .price-main {
    display: flex;
    padding: 0 11%;
    margin-top: 38px;
    position: relative;
    @media screen and (max-width: 1000px) {
      padding: 0 8%;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 749px) {
      padding: 0 7.35%;
      flex-wrap: wrap;
      margin-top: 20px;
    }
    .price-item {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 35px;
      box-sizing: border-box;
      min-height: 400px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 5px 10px rgba(47, 124, 191, 0.1);
      border-radius: 20px;
      margin-right: 35px;
      position: relative;
      @media screen and (max-width: 1050px) {
        min-height: 420px;
      }
      @media screen and (max-width: 1000px) {
        width: 100%;
        min-height: 360px;
        margin-right: 0;
        margin-bottom: 40px;
        flex: auto;
      }
      @media screen and (max-width: 749px) {
        min-height: 300px;
      }
      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
      .item-name {
        font-size: 28px;
        font-weight: 500;
      }
      .item-desc {
        font-size: 16px;
        color: #666;
        line-height: 26px;
        margin-top: 15px;
      }
      .item-look {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #268ff8;
        margin-top: 15px;
        &:hover{
          cursor: pointer;
        }
        @media screen and (max-width: 749px) {
          display: none;
        }
      }
      .item-free {
        color: #268ff8;
        font-size: 28px;
        font-weight: 500;
        position: absolute;
        bottom: 115px;
        left: 35px;
        @media screen and (max-width: 749px) {
          bottom: 35px;
        }
      }
      .item-free1{
        display: none;
        @media screen and (max-width: 749px) {
          display: block;
        }
      }
      .item-btn,
      .item-btn2{
        position: absolute;
        left: 35px;
        bottom: 35px;
        width: calc(100% - 70px);
        height: 50px;
        border-radius: 30px;
        border: 1px solid #ccc;
        font-size: 20px;
        color: #666;
        margin-top: 30px;
        @media screen and (max-width: 749px) {
          display: none;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .item-btn {
        &:hover {
          border: 1px solid #268ff8;
          color: #268ff8;
        }
      }
      .item-btn2 {
        &:hover {
          background: rgba(38, 143, 248, 0.1);
        }
      }
      .item-btn1 {
        position: absolute;
        left: 35px;
        bottom: 35px;
        width: calc(100% - 70px);
        height: 50px;
        background: rgba(38, 143, 248, 1);
        border-radius: 30px;
        font-size: 20px;
        color: #fff;
        margin-top: 28px;
        @media screen and (max-width: 749px) {
          display: none;
        }
        &:hover {
          cursor: pointer;
          background: #3c9af9;
        }
      }
      .item-btn2 {
        border: 1px solid #268ff8;
        color: #268ff8;
      }
    }
    .version-compare {
      position: absolute;
      right: 11%;
      top: -30px;
      transform: translateY(-100%);
      width: 100px;
      height: 32px;
      box-sizing: border-box;
      padding-left: 18px;
      border: 1px solid #cccccc;
      border-radius: 30px;
      font-size: 14px;
      color: #666;
      display: flex;
      align-items: center;
      @media screen and (max-width: 749px) {
        position: static;
        margin: 0 auto;
        margin-top: 20px;
        transform: translateY(0);
        margin-bottom: 30px;
      }
      &:hover {
        cursor: pointer;
        border: 1px solid #268ff8;
        color: #268ff8;
      }
    }
  }
}
</style>