<template>
  <div class="about">
    <div class="about-main">
      <div class="about-left">
        <div class="main-top">关于我们</div>
        <div class="about-tip">
          和慕PMS由广州和光同行信息科技产业有限公司开发，是一款全屋智能的项目管理生产力工具，功能包含项目管理，文档管理，团队管理，数据统计和第三方服务等。
        </div>
        <div class="about-tip">
          和慕PMS为全屋智能品牌商、大型经销商、中小企业和个人提供服务，旨在利用行业经验和前沿技术，帮助智能家居行业参与者改善工作效率和方法。
        </div>
        <div class="about-tip">
          和慕PMS以全屋智能项目管理和全流程服务为应用场景，围绕服务供需两端，通过平台签约的服务机构和个人为线下店铺提供方案设计、安装调试等服务，旨在打造以线下店铺为核心的数字化、在线化、标准化的服务交易协作网络。
        </div>
        <div class="about-tip">
          公司地址: 广州市海珠区新港西路135号中大科技园B座1610
        </div>
      </div>
      <img class="about-bg" src="../../assets/images/about-bg.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped lang='scss'>
.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 11%;
  box-sizing: border-box;
  @media screen and (max-width: 1000px) {
    padding: 100px 8%;
  }
  @media screen and (max-width: 749px) {
    padding: 60px 7.35%;
  }
  .about-main {
    width: 100%;
    padding: 60px 120px 60px 100px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(326deg, #eaf1ff 0%, #c2d6ff 100%);
    border-radius: 20px;
    @media screen and (max-width: 1299px) {
      padding: 60px 60px 60px 80px;
    }
    @media screen and (max-width: 1000px) {
      padding: 60px 60px 30px 60px;
      flex-direction: column;
      justify-content: center;
    }
    @media screen and (max-width: 749px) {
      padding: 50px 40px 20px 40px;
    }
    .about-left {
      width: calc(100% - 420px);
      @media screen and (max-width: 1299px) {
        width: calc(100% - 360px);
      }
      @media screen and (max-width: 1000px) {
        width: 100%;
      }
      .main-top {
        font-size: 44px;
        font-weight: 500;
        margin-bottom: 34px;
        @media screen and (max-width: 1299px) {
          font-size: 40px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 36px;
        }
        @media screen and (max-width: 749px) {
          font-size: 32px;
        }
      }
      .about-tip {
        width: 100%;
        font-size: 14px;
        line-height: 26px;
        color: #666;
        margin-bottom: 18px;
        @media screen and (max-width: 749px) {
          line-height: 24px;
          margin-bottom: 24px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .about-bg {
      width: 420px;
      height: 420px;
      @media screen and (max-width: 1299px) {
        width: 320px;
        height: 320px;
        margin-top: 23px;
      }
      @media screen and (max-width: 1000px) {
        width: 300px;
        height: 300px;
      }
      @media screen and (max-width: 749px) {
        width: 240px;
        height: 240px;
      }
    }
  }
}
</style>