<template>
  <div class="fifth-screen">
    <div class="fifth-item">
      <div class="fifth-main">
        <div class="main-top">深度集成全屋智能系统</div>
        <div class="main-middle">
          与第三方全屋智能系统深度集成，系统配置更方便<br />
          在线快速调试设备，随时随地掌握项目实施情况<br />
          售后服务更快捷，不必次次上门，降本增效
        </div>
        <div class="main-middle1">
          <span>与第三方全屋智能系统深度集成，系统配置更方便</span>
          <span>在线快速调试设备，随时随地掌握项目实施情况</span>
          <span>售后服务更快捷，不必次次上门，降本增效</span>
        </div>
        <a href="https://web2.homepms.com" target="_blank" class="main-enter">
          <span>立即试用</span>
          <span class="el-icon-arrow-right"></span>
        </a>
      </div>
      <img class="show-img" src="../../assets/images/fifth-1.png" alt="" />
    </div>
    <div class="fifth-item fifth-item1">
      <img class="show-img" src="../../assets/images/fifth-2.png" alt="" />
      <div class="fifth-main">
        <div class="main-top">多端同步、随时随地</div>
        <div class="main-middle">
          联网时自动在所有设备间同步<br />
          可以随时随地在电脑、手机、平板上访问所有内容
        </div>
        <div target="_blank" class="main-middle1">
          <span>联网时自动在所有设备间同步</span>
          <span>可以随时随地在电脑、手机、平板上访问所有内容</span>
        </div>
        <a href="https://web2.homepms.com" target="_blank" class="main-enter">
          <span>立即试用</span>
          <span class="el-icon-arrow-right"></span>
        </a>
      </div>
    </div>
    <div class="fifth-item fifth-item2">
      <div class="fifth-main">
        <div class="main-top">稳定、安全、不丢文档</div>
        <div class="main-middle">
          专业团队保驾护航<br />
          不仅快速、便捷、低成本，而且更安全可靠
        </div>
        <div class="main-middle1">
          <span>专业团队保驾护航</span>
          <span>不仅快速、便捷、低成本，而且更安全可靠</span>
        </div>
        <a href="https://web2.homepms.com" target="_blank" class="main-enter">
          <span>立即试用</span>
          <span class="el-icon-arrow-right"></span>
        </a>
      </div>
      <img class="show-img" src="../../assets/images/fifth-3.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped lang='scss'>
.fifth-screen {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1299px) {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  @media screen and (max-width: 1000px) {
    padding-top: 60px;
  }
  @media screen and (max-width: 749px) {
    padding-top: 50px;
  }
  .fifth-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 78%;
    padding: 70px 80px 70px 100px;
    margin-bottom: 60px;
    box-sizing: border-box;
    border-radius: 30px;
    background: linear-gradient(
      89deg,
      rgba(135, 207, 255, 0.61) 0%,
      rgba(198, 223, 255, 0.13) 100%
    );
    @media screen and (max-width: 1299px) {
      padding: 50px 40px 46px 50px;
    }
    @media screen and (max-width: 1000px) {
      width: 83%;
      padding: 53px 0 53px 80px;
      border-radius: 25px;
    }
    @media screen and (max-width: 749px) {
      width: 83.5%;
      padding: 54px 40px 55px 40px;
      border-radius: 25px;
      min-height: 394px;
      align-items: flex-start;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .fifth-main {
      .main-top {
        font-size: 36px;
        font-weight: 500;
        @media screen and (max-width: 1299px) {
          font-size: 32px;
        }
        @media screen and (max-width: 749px) {
          font-size: 28px;
          line-height: 40px;
        }
      }
      .main-middle {
        font-size: 16px;
        line-height: 28px;
        margin-top: 24px;
        @media screen and (max-width: 1299px) {
          line-height: 30px;
          margin-top: 23px;
        }
        @media screen and (max-width: 749px) {
          display: none;
        }
      }
      .main-middle1 {
        display: none;
        margin-top: 19px;
        @media screen and (max-width: 749px) {
          display: flex;
          flex-direction: column;
          span {
            margin-bottom: 15px;
            line-height: 26px;
            font-size: 16px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .main-enter {
        display: flex;
        align-items: center;
        margin-top: 29px;
        font-size: 18px;
        color: #268ff8;
        @media screen and (max-width: 1299px) {
          font-size: 16px;
        }
        @media screen and (max-width: 749px) {
          display: none;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .show-img {
      width: 40%;
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
  }
  .fifth-item1 {
    justify-content: flex-start;
    background: linear-gradient(
      271deg,
      rgba(255, 205, 87, 0.6) 0%,
      rgba(219, 165, 119, 0.02) 100%
    );
    .fifth-main {
      margin-left: 100px;
      @media screen and (max-width: 1000px) {
        margin-left: 0px;
      }
    }
  }
  .fifth-item2 {
    background: linear-gradient(
      89deg,
      rgba(135, 176, 255, 0.48) 0%,
      rgba(202, 186, 222, 0.02) 100%
    );
  }
}
</style>