<template>
  <div class="forth-screen">
    <div class="forth-header d-flex-center">和慕PMS这样提高您的企业生产力</div>
    <div class="forth-main">
      <div class="forth-item">
        <div class="item-main">
          <div class="main-top">项目管理</div>
          <div class="main-middle">
            项目流程全覆盖<br />
            项目组协同办公<br />
            历史操作全记录
          </div>
          <a href="https://web2.homepms.com" target="_blank" class="main-enter">
            <span>立即试用</span>
            <span class="el-icon-arrow-right"></span>
          </a>
        </div>
        <img class="show-img" src="../../assets/images/forth-1.png" alt="" />
      </div>
      <div class="forth-item">
        <img class="show-img" src="../../assets/images/forth-2.png" alt="" />
        <div class="item-main item-main1">
          <div class="main-top">文档管理</div>
          <div class="main-middle">
            文件按项目存储，按用途分类<br />
            文件云端同步，随时查看<br />
            文档集中管理，员工离职不丢失
          </div>
          <a href="https://web2.homepms.com" target="_blank" class="main-enter">
            <span>立即试用</span>
            <span class="el-icon-arrow-right"></span>
          </a>
        </div>
      </div>

      <div class="forth-item">
        <div class="item-main">
          <div class="main-top">团队管理</div>
          <div class="main-middle">
            统一管理团队成员权限<br />
            任务安排更方便，项目组灵活组建<br />
            工作交接同时项目权限自动交接
          </div>
          <a href="https://web2.homepms.com" target="_blank" class="main-enter">
            <span>立即试用</span>
            <span class="el-icon-arrow-right"></span>
          </a>
        </div>
        <img class="show-img" src="../../assets/images/forth-3.png" alt="" />
      </div>
      <div class="forth-item">
        <img class="show-img" src="../../assets/images/forth-4.png" alt="" />
        <div class="item-main item-main1">
          <div class="main-top">数据统计</div>
          <div class="main-middle">
            项目数据全面统计分析<br />
            团队工作情况随时了解<br />
            详实报表，可导出Excel
          </div>
          <a href="https://web2.homepms.com" target="_blank" class="main-enter">
            <span>立即试用</span>
            <span class="el-icon-arrow-right"></span>
          </a>
        </div>
      </div>
    </div>
    <div class="forth-main1">
      <div class="main1-item">
        <div class="main1-item-top">项目管理</div>
        <div class="main1-item-middle">
          项目流程全覆盖<br />
          项目组协同办公<br />
          历史操作全记录
        </div>
        <img
          class="main1-item-bottom"
          src="../../assets/images/forth-1.png"
          alt=""
        />
      </div>
      <div class="main1-item">
        <div class="main1-item-top">文档管理</div>
        <div class="main1-item-middle">
          文件按项目存储，按用途分类<br />
          文件云端同步，随时查看<br />
          文档集中管理，员工离职不丢失
        </div>
        <img
          class="main1-item-bottom"
          src="../../assets/images/forth-2.png"
          alt=""
        />
      </div>
      <div class="main1-item">
        <div class="main1-item-top">团队管理</div>
        <div class="main1-item-middle">
          统一管理团队成员权限<br />
            任务安排更方便，项目组灵活组建<br />
            工作交接同时项目权限自动交接
        </div>
        <img
          class="main1-item-bottom"
          src="../../assets/images/forth-3.png"
          alt=""
        />
      </div>
      <div class="main1-item">
        <div class="main1-item-top">数据统计</div>
        <div class="main1-item-middle">
          项目数据全面统计分析<br />
            团队工作情况随时了解<br />
            详实报表，可导出Excel
        </div>
        <img
          class="main1-item-bottom"
          src="../../assets/images/forth-4.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped lang='scss'>
.forth-screen {
  padding: 100px 0;
  background: #fafafb;
  @media screen and (max-width: 1299px) {
    padding-bottom: 50px;
  }
  @media screen and (max-width: 1000px) {
    padding-bottom: 57px;
    padding-top: 80px;
  }
  @media screen and (max-width: 749px) {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .forth-header {
    font-size: 44px;
    font-weight: 500;
    margin-bottom: 100px;
    @media screen and (max-width: 1299px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 36px;
    }
    @media screen and (max-width: 749px) {
      font-size: 28px;
      padding: 0 58px;
      line-height: 44px;
      text-align: center;
      margin-bottom: 52px;
    }
  }
  .forth-main {
    @media screen and (max-width: 749px) {
      display: none;
    }
    .forth-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 140px;
      @media screen and (max-width: 1299px) {
        margin-bottom: 123px;
      }
      @media screen and (max-width: 1000px) {
        margin-bottom: 115px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .item-main {
        width: 500px;
        box-sizing: border-box;
        @media screen and (max-width: 1299px) {
          width: 397px;
        }
        @media screen and (max-width: 1000px) {
          width: 38vw;
        }
        .main-top {
          font-size: 36px;
          font-weight: 500;
          @media screen and (max-width: 1299px) {
            font-size: 32px;
          }
          @media screen and (max-width: 1000px) {
            font-size: 28px;
          }
        }
        .main-middle {
          margin-top: 21px;
          font-size: 22px;
          color: #666;
          line-height: 40px;
          @media screen and (max-width: 1299px) {
            margin-top: 22px;
            font-size: 20px;
            line-height: 36px;
          }
          @media screen and (max-width: 1000px) {
            margin-top: 24px;
            font-size: 16px;
            line-height: 28px;
          }
        }
        .main-enter {
          display: flex;
          align-items: center;
          margin-top: 40px;
          font-size: 18px;
          color: #268ff8;
          &:hover{
            cursor: pointer;
          }
          @media screen and (max-width: 1000px) {
            font-size: 16px;
          }
        }
      }
      .item-main1 {
        padding-left: 100px;
        @media screen and (max-width: 1299px) {
          padding-left: 97px;
        }
        @media screen and (max-width: 1000px) {
          padding-left: 51px;
        }
      }
      .show-img {
        width: 580px;
        height: 440px;
        @media screen and (max-width: 1299px) {
          width: 454px;
          height: 351px;
        }
        @media screen and (max-width: 1000px) {
          width: 47vw;
          height: auto;
        }
      }
    }
  }
  .forth-main1 {
    display: none;
    @media screen and (max-width: 749px) {
      display: block;
    }
    .main1-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 80px;
      &:first-child{
        margin-top: 0;
      }
      .main1-item-top {
        font-size: 24px;
        font-weight: 500;
      }
      .main1-item-middle {
        text-align: center;
        font-size: 16px;
        line-height: 28px;
        margin-top: 14px;
        margin-bottom: 34px;
      }
      .main1-item-bottom {
        width: 85.3vw;
      }
    }
  }
}
</style>